$black: #1f2123;
$white: #ffffff;
$pink: #ff64a5;
$dark-blue: #0096fa;
$blue: #73c8ff;
$light-blue: #e0eefd;
$green: #41b900;
$light-green: #e9f8e1;
$orange: #ff913c;
$orange-light: #ffeee0;
$primary: $pink;
$primary-dark: #d23778;
$primary-extra-dark: #c42c6c;
$primary-light: #ffeef5;
$invalid: #dc3545;
$focus: #efb0ca;
$error: #cc0000;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #606b76;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$link-color: $primary-dark;
$link-hover-color: $primary-extra-dark;
$link-decoration: underline;

$enable-rounded: false;
$enable-shadows: false;
$enable-responsive-font-sizes: true;

$component-active-bg: $primary-dark;

$list-group-active-bg: $light-blue;
$list-group-active-color: $black;

$h1-font-size: 42px;
$h2-font-size: 32px;
$h3-font-size: 22px;
$h4-font-size: 18px;

$input-bg: $gray-100;
$input-disabled-bg: $gray-200;
$input-color: $black;
$input-border-color: $gray-600;

// these should be the same in csss/breakpoints.ts
$grid-breakpoints: (
  xs: 0,
  sm: 550px,
  md: 768px,
  lg: 915px,
  xl: 1200px,
);

@import "bootstrap/scss/bootstrap.scss";

$border-color: $gray-600;

:root {
  --color-primary: #{$primary};
  --color-black: #{$black};
  --color-white: #{$white};
  --color-blue: #{$blue};
  --color-light-blue: #{$light-blue};
  --color-dark-blue: #{$dark-blue};
  --color-light-green: #{$light-green};
  --color-green: #{$green};
  --color-orange: #{$orange};
  --color-orange-light: #{$orange-light};
  --color-primary-dark: #{$primary-dark};
  --color-primary-light: #{$primary-light};
  --color-primary-extra-dark: #{$primary-extra-dark};
  --color-gray-100: #{$gray-100};
  --color-gray-200: #{$gray-200};
  --color-gray-300: #{$gray-300};
  --color-gray-400: #{$gray-400};
  --color-gray-500: #{$gray-500};
  --color-gray-600: #{$gray-600};
  --color-gray-700: #{$gray-700};
  --color-gray-800: #{$gray-800};
  --color-gray-900: #{$gray-900};
  --color-invalid: #{$invalid};
  --color-focus: #{$focus};
  --color-error: #{$error};
}

@import "./custom_inputs.scss";
@import "./buttons.scss";
@import "./form.scss";
@import "./text.css";
@import "./lists.css";
@import "./react-datepicker";
@import "./modal.scss";
@import "./popover.css";
@import "./background.scss";
@import "./badge.css";
@import "./alert.css";
@import "./dropdown.css";

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  background-color: $gray-300;
  color: $gray-900;
}

.nav-tabs .nav-link:hover {
  background-color: $gray-200;
}

.nav-tabs .nav-link.active:hover {
  background-color: white;
  pointer-events: none;
}

a {
  outline-color: var(--color-focus);
}

.h1-size {
  font-size: $h1-font-size;
}

.h2-size {
  font-size: $h2-font-size;
}

.h3-size {
  font-size: $h3-font-size;
}

.h4-size {
  font-size: $h4-font-size;
}

.border-primary-dark {
  border-color: $primary-dark !important;
}

.primary-nav-link {
  color: #{$gray-900};
  padding-left: calc(1rem + 4px);
}

.primary-nav-link:hover {
  color: #{$primary-extra-dark};
}

.primary-nav-link-active {
  padding: 0.5rem 1rem;
  padding-left: 1rem;
  border-left: 4px solid var(--color-gray-600);
  background-color: var(--color-gray-200);
}

.secondary-nav-link {
  color: #{$gray-900};
  margin: 0.5rem 1rem;
  margin-left: 2rem;
  padding: 7px 5px 6px 4px;
  max-width: max-content;
}

.secondary-nav-link-active {
  margin: 0.5rem 1rem;
  margin-left: 2rem;
  font-weight: 900;
  background-color: var(--color-gray-200);
  padding: 7px 5px 6px 4px;
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif !important;
  max-width: max-content;
}

@media (max-width: 915px) {
  .secondary-nav-link-active,
  .secondary-nav-link {
    background-color: var(--color-gray-200);
  }
}

.public-nav-link-active .link-text {
  border-bottom: 6px solid var(--color-primary);
}

:root {
  --navigation-width: 260px;
  --header-height: 65px;
  --banner-header-height: 129px;
}

@import "./react-dropdown-tree-select-custom-theme.scss";

.react-dropdown-tree-select {
  .dropdown .dropdown-trigger.arrow.bottom {
    &:after {
      opacity: 1;
      border-left: 1px solid var(--color-gray-400);
      height: 22px;
      margin-right: 3px;
      padding-left: 28px;
      top: 8px;
    }
  }
}

.popover {
  max-width: 400px;
}

.bg-light-primary {
  background-color: #ffeef5 !important;
}

.bg-primary-light {
  background-color: var(--color-primary-light);
}

.bg-gray-100 {
  background-color: var(--color-gray-100);
}

.bg-gray-200 {
  background-color: var(--color-gray-200);
}

.bg-primary-dark {
  background-color: var(--color-primary-dark);
}

.border-gray-bottom {
  border-bottom: 1px solid var(--color-gray-400);
}

.border-gray-300-bottom {
  border-bottom: 1px solid var(--color-gray-300);
}

.border-gray-300-bottom-2 {
  border-bottom: 2px solid var(--color-gray-300);
}

.border-gray-300 {
  border: 1px solid var(--color-gray-300);
}

.color-gray-900 {
  color: var(--color-gray-900);
}

.dashboard-card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
  background-color: var(--color-white);
}

.disable-bg-effects {
  background-color: transparent !important;

  &:active,
  &:focus,
  &:target,
  &:hover {
    background-color: transparent !important;
  }
}

.icon-tooltip > .tooltip-inner {
  max-width: 100%;
}

.box-shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
}

.border-gray-400 {
  border: 1px solid var(--color-gray-400);
}

.border-bottom-gray-300 {
  border-bottom: 1px solid var(--color-gray-300);
}

.bg-orange-light {
  background-color: var(--color-orange-light);
}

.fullScreenModal {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.dropdown-trigger.arrow.disabled {
  background-color: var(--color-gray-200);
  border: 0;
  input {
    color: var(--color-gray-600) !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.datahub-image-upload-error-popover {
  position: fixed !important;
  z-index: 800 !important;
  max-width: 400px;
  background: var(--color-white);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.33);
}
