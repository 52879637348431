ul.color-primary {
  list-style: none;
  padding-inline-start: 1.5rem;
  margin-left: 0.5rem;
  padding-left: 0;
}

ul.color-primary li {
  padding-left: 0.5rem;
  margin-top: 0.25rem;
}

ul.color-primary li::before {
  content: "• ";
  color: var(--color-primary-dark);
  font-size: 36px;
  line-height: 23px;
  vertical-align: text-bottom;
}
