button:focus {
  outline-color: var(--color-focus);
}

.btn {
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  color: #{$black};
  padding: 0.5rem 1.25rem;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem #{$focus};
  outline-color: var(--color-focus);
}

// Primary buttons

.btn-primary:hover {
  color: #{$black};
  background-color: #{$primary-dark};
  border-color: #{$primary-dark};
}

.btn-primary:focus {
  color: #{$black};
}

.btn-primary:disabled {
  opacity: 1;
  color: #{$gray-600};
  border: 1px solid transparent;
  background-color: #{$gray-200};
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #{$black};
}

// Secondary buttons

.btn-light {
  color: #{$black} !important;
  background-color: #{$gray-100};
  border-color: #{$gray-500};
}

.btn-light:hover {
  color: #{$black};
  background-color: #{$gray-200};
  border-color: #{$gray-500};
}

.btn-light:not(:disabled):not(.disabled):active {
  color: #{$black};
  background-color: #{$gray-100} !important;
  border-color: #{$gray-500};
}

// Tertiary buttons

.btn-secondary {
  color: #{$black} !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-secondary:disabled {
  pointer-events: none;
  opacity: 1;
  color: #{$gray-600} !important;
  border: none;
}

.btn-secondary:hover {
  color: #{$black};
  background-color: #{$gray-200} !important;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  color: #{$black};
  background-color: #{$gray-100} !important;
  border-color: transparent !important;
}

// Ghost buttons (quaternary)

.btn-ghost {
  color: #{$primary-dark};
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0.3rem 0;
}

.btn-ghost:hover {
  color: #{$primary-extra-dark};
  border-color: transparent !important;
}

.btn-ghost:not(:disabled):not(.disabled):active {
  color: #{$primary-extra-dark};
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-ghost:focus {
  color: #{$primary-dark};
}

// Outline buttons for landing page

.btn-outline-primary {
  color: #{$black};
}

.btn-outline-primary:hover {
  color: #{$primary-dark};
  background: transparent;
}

// fit content
.btn-fit-content {
  min-width: fit-content;
  min-width: -moz-fit-content;
}

// wide button
.btn-wide {
  width: 10rem;
}

// New variant buttons

.datahub-button {
  font-family: AvenirRoman;
  background: none;
  border-radius: 0;
  &:focus {
    outline: 1px solid !important;
    box-shadow: 0 0 0 0.2rem #{$focus} !important;
    outline-color: var(--color-focus) !important;
  }
}

@mixin button-flex-properties {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.datahub-button-primary {
  @include button-flex-properties;
  border: 0;
  padding: 11px 16px;
  background: var(--color-primary);
  font-weight: 800;
  font-size: 16px;

  &:hover {
    background: var(--color-primary-dark);
  }

  &.disabled {
    background: var(--color-gray-200);
  }
}

.datahub-button-secondary {
  @include button-flex-properties;
  padding: 11px 16px;
  background: var(--color-gray-100);
  border: 1px solid var(--color-gray-500);
  font-weight: 800;
  color: var(--color-black);

  &.disabled {
    background: var(--color-gray-200);
    color: var(--color-gray-600);
    border: 0;
  }
}

.datahub-button-outline {
  @include button-flex-properties;
  padding: 11px 16px;
  border: 1px solid var(--color-primary);
  background: none;
  font-weight: 800;
  color: var(--color-black);
  &:hover {
    color: var(--color-primary-extra-dark);
  }
  &:focus {
    color: var(--color-black);
  }
  &:active {
    color: var(--color-primary-extra-dark);
  }
  &.disabled {
    background: var(--color-gray-200);
    color: var(--color-gray-600);
  }
}

.datahub-button-ghost,
.datahub-button-ghost-pink {
  @include button-flex-properties;
  padding: 11px 16px;
  border: 0;
  font-weight: 800;
  background: transparent;
  align-items: center;

  &.disabled {
    color: var(--color-gray-600);
  }
  &:hover,
  &:active {
    background-color: var(--color-gray-200);
  }
}

.datahub-button-ghost-pink {
  color: var(--color-primary-dark);
  padding: 0px 4px;
  margin: 0;
}

.datahub-button-sm {
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 18px;
  padding-left: 17px;
}
