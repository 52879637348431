.bg-gray-100 {
  background: var(--color-gray-100) !important;
}
.bg-gray-200 {
  background: var(--color-gray-200) !important;
}
.bg-gray-300 {
  background: var(--color-gray-300) !important;
}
.bg-gray-400 {
  background: var(--color-gray-400) !important;
}
.bg-gray-500 {
  background: var(--color-gray-500) !important;
}
.bg-gray-600 {
  background: var(--color-gray-600) !important;
}
.bg-gray-700 {
  background: var(--color-gray-700) !important;
}
.bg-gray-800 {
  background: var(--color-gray-800) !important;
}
.bg-gray-900 {
  background: var(--color-gray-900) !important;
}

.bg-light-blue {
  background: var(--color-light-blue) !important;
}
