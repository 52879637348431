.react-datepicker {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1rem !important;

  &__close-icon::after {
    background-color: var(--color-primary) !important;
  }

  &-wrapper {
    max-width: 100%;

    &.autoWidth {
      width: auto;
    }

    input {
      max-width: 100%;
      padding: 0.5rem 0.75rem 0.4rem 0.75rem;

      &.full-width {
        width: 100% !important;
        border-bottom: 1px solid var(--color-gray-400) !important;
      }
    }
  }

  &-popper {
    z-index: 2 !important;
  }

  .react-datepicker {
    &__triangle {
      margin-top: -7px !important;

      &:after {
        border-bottom-color: white !important;
      }
    }

    &__navigation {
      top: 20px !important;

      &--previous {
        left: 25px !important;

        &--disabled .react-datepicker__navigation-icon::before {
          border-color: var(--color-gray-400) !important;
        }
      }

      &--next {
        right: 25px !important;
      }

      &-icon::before {
        top: 14px;
        border-color: var(--color-black) !important;
      }
    }

    &__month-container {
      padding: 25px;

      .react-datepicker {
        &__header {
          background-color: white !important;
          padding: 0 !important;
          border: none !important;

          .react-datepicker__day-names {
            margin: 5px 0 !important;
            font-size: 12px;

            .react-datepicker__day-name {
              line-height: normal !important;
              margin: 0 !important;
              width: 41px !important;
            }
          }

          .react-datepicker__current-month {
            font-size: 1.2rem !important;
            padding-bottom: 10px !important;
          }
        }

        &__month {
          margin: 0 !important;

          .react-datepicker__day {
            outline: 1px solid var(--color-gray-200);
            width: 40px !important;
            line-height: 40px !important;
            margin: 1px 0 0 1px !important;

            &:hover {
              border-radius: 0 !important;
              background-color: var(--color-primary-light) !important;
            }

            &--disabled {
              background-color: var(--color-gray-100) !important;
            }

            &--keyboard-selected,
            &--today {
              &:not(&--selected) {
                background-color: transparent;
              }

              border-radius: 0;
            }

            &--range-start,
            &--in-selecting-range,
            &--in-range,
            &--selected {
              border-radius: 0 !important;
              background: var(--color-primary);
              color: var(--color-black);
            }

            &--outside-month {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}
