input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 2rem;
  padding-left: 2rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-control-checked {
  border: 2px solid #{$black};
}

// CHECKED RADIOBUTTON
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
  width: 24px;
  height: 24px;
  transform: translate(-20%, -15%);
}

.custom-control-label::before {
  position: absolute;
  left: -2rem;
  display: block;
  width: 22px;
  height: 22px;
  pointer-events: none;
  content: "";
  background-color: #{$white};
  border: #{$gray-600} solid 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom-control-label::after {
  position: absolute;
  left: -1.75rem;
  display: block;
  width: 22px;
  height: 22px;
  content: "";
  background: no-repeat 50% / 50% 50%;
  top: calc(50% - 0.5rem);
}

// CHECKED CHECKBOX
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  height: 36px;
  width: 36px;
  transform: translate(-30%, -28%);
  -webkit-transform: translate3d(-30%, -28%, 0);
}

// DISABLED
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #{$gray-200};
  border-color: #{$gray-400};
}

// DISABLED CHECKED
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #{$gray-500};
}

.custom-control-label {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif;
}

.custom-control-input[checked] ~ .custom-control-label,
.custom-control-input:checked ~ .custom-control-label {
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif;
}

.custom-switch .custom-control-label::before {
  width: 3rem;
  height: 1.5rem;
  left: -2.25rem;
  border-radius: 1.5rem;
}

.custom-switch .custom-control-label::after {
  top: 3px;
  left: calc(-2.25rem + 3px);
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
  background-color: var(--color-gray-500);
  border-radius: 1.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f8f9fa;
  transform: translateX(1.5rem);
}

.custom-switch .custom-control-label {
  padding-left: 1.25rem;
}
