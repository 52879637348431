.popover {
  box-shadow: 0 2px 6px 1px var(--color-gray-400);
  border: 0 !important;
}

.popover-header {
  padding: 1rem 1.5rem !important;
  font-size: 1.1rem !important;
  color: var(--color-gray-800) !important;
}

.popover-body {
  font-size: 0.9rem;
  color: var(--color-gray-800) !important;
  padding: 1rem 1.5rem !important;
}
