.alert {
  font-size: 1rem !important;
  padding: 1rem !important;
}

.alert-success {
  background-color: var(--color-light-green) !important;
  border: 0 !important;
  border-left: 6px solid var(--color-green) !important;
  color: var(--color-gray-800) !important;
}

.alert-info {
  background-color: var(--color-light-blue) !important;
  border: 0 !important;
  border-left: 6px solid var(--color-blue) !important;
  color: var(--color-gray-800) !important;
}

.alert-danger {
  background-color: var(--color-primary-light) !important;
  border: 0 !important;
  border-left: 6px solid var(--color-primary-dark) !important;
  color: var(--color-gray-800) !important;
}

.alert-info-alt {
  background-color: var(--color-orange-light) !important;
  border: 0 !important;
  border-left: 6px solid var(--color-orange) !important;
  color: var(--color-gray-800) !important;
}
