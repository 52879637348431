.form-control:disabled,
.form-control[readonly] {
  border: 0;
  color: $gray-600;
}

.form-control:focus {
  color: $black;
  background-color: $white;
  border-color: #343a40;
  outline: 0;
  box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  border-width: 2px;
}

.form-control {
  box-shadow: none;
  background-color: $white;
  padding: 0.5rem 0.75rem 0.4rem 0.75rem;
  height: calc(1.5em + 0.75rem + 4px);
}

.is-invalid-border {
  border: 1px solid var(--color-invalid) !important;
}

.is-invalid-border-bottom {
  border-bottom: 1px solid var(--color-invalid) !important;
}

label {
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif;
}

.columns-2 {
  columns: 2;
}

input[type="search"]::-webkit-search-cancel-button {
  height: 1.3em;
  width: 1.3em;
  background: url(../../src/icons/clear.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0.3;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 0.5;
}
