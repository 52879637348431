@font-face {
  font-family: AvenirRoman;
  src: local("Avenir Roman"), local("Avenir-Roman"),
    url("../fonts/avenirroman.woff2") format("woff2"),
    url("../fonts/avenirroman.woff") format("woff");
}

@font-face {
  font-family: AvenirHeavy;
  src: local("Avenir Heavy"), local("Avenir-Heavy"),
    url("../fonts/avenirheavy.woff2") format("woff2"),
    url("../fonts/avenirheavy.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: AvenirBlack;
  src: local("Avenir Black"), local("Avenir-Black"),
    url("../fonts/avenirblack.woff2") format("woff2"),
    url("../fonts/avenirblack.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: AvenirBook;
  src: local("Avenir Book"), local("Avenir-Book"), url("../fonts/avenirbook.woff2") format("woff2"),
    url("../fonts/avenirbook.woff") format("woff");
  font-weight: normal;
}

:root {
  --font-light: "AvenirRoman", "Helvetica Neue", Arial, sans-serif;
  --font-heavy: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif;
  --font-weight-bold: 700;
  --font-weight-regular: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
}

pre {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif !important;
}

body {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif !important;
}

.font-light {
  font-family: "AvenirRoman", "Helvetica Neue", Arial, sans-serif !important;
  font-weight: 400 !important;
}

.clamp-1,
.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  -webkit-line-clamp: 2;
}

.font-heavy {
  font-family: "AvenirHeavy", "Helvetica Neue", Arial, sans-serif !important;
  font-weight: 800 !important;
}
.bold-text {
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
}

.text-normal-size {
  font-size: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.text-smaller {
  font-size: 0.8rem !important;
}

.text-larger {
  font-size: 18px !important;
}

.text-landing-h3 {
  font-size: 35px !important;
}

.text-landing-h4 {
  font-size: 21px !important;
}

.color-primary-dark {
  color: var(--color-primary-dark) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.color-error {
  color: var(--color-error) !important;
}

.color-gray-400 {
  color: var(--color-gray-400) !important;
}
.color-gray-500 {
  color: var(--color-gray-500) !important;
}
.color-gray-600 {
  color: var(--color-gray-600) !important;
}
.color-gray-700 {
  color: var(--color-gray-700) !important;
}
.color-gray-800 {
  color: var(--color-gray-800) !important;
}
.color-gray-900 {
  color: var(--color-gray-900) !important;
}
