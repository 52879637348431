.tag {
  font-family: var(--font-light);
  background-color: var(--color-light-blue);
  padding: 3px 0 3px 6px;
  display: inline-block;
  font-size: 0.9rem;
  color: var(--color-gray-800);
  display: inline-flex;
}
.tag:focus-within {
  background-color: #e9e9e9;
}
.tag-remove {
  color: transparent;
  background-color: transparent;
  background-position-y: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='14' width='14' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-19bqh2r'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E");
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 4px;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 60%;
}
.tag-remove:focus {
  color: #3c3c3c;
}

.node > label {
  cursor: pointer;
  margin-left: 2px;
  margin-bottom: 0;
  font-family: var(--font-light);
}

.node.checked > label {
  font-family: var(--font-heavy);
}

.tag-list {
  width: calc(100% - 30px);
  display: block;
  padding: 0;
  margin: 0;
}
.tag-item {
  display: inline-block;
  margin: 4px;
}
.tag-item .search {
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-black) !important;
}
.tag-item:last-child {
  margin-right: 4px;
}
.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}
.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}
.node.focused {
  background-color: #f4f4f4;
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: " ";
}
.toggle.collapsed:after {
  content: "+";
  padding: 0 4px;
  font-style: normal;
  font-weight: bold;
}
.toggle.expanded:after {
  content: "-";
  padding: 0 7px;
  font-style: normal;
  font-weight: bold;
}
.searchModeOn .toggle {
  display: none;
}

.checkbox-item {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.node-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.searchModeOn .checkboxItem {
  margin-left: 2rem;
}

.checkboxItem .node-label::before {
  content: "";
  position: absolute;
  left: -1.75rem;
  top: 0;
  z-index: 1;
  width: 22px;
  height: 22px;
  border: 1px solid #{$gray-600};
  background-color: #{$white};
}

.checkboxItem .node-label::after {
  position: absolute;
  top: 0;
  left: -1.55rem;
  display: block;
  width: 22px;
  height: 22px;
  content: "";
  background: no-repeat 54% / 50% 50%;
  z-index: 2;
  background-position-y: 11px;
}

.checked .node-label::before {
  background: var(--color-primary-dark);
}

.checked .node-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  height: 36px;
  width: 36px;
  transform: translate(-30%, -28%);
}

.radio-item {
  height: 20px;
  width: 20px;
  margin: 8px 4px 0 0;
}

.checkbox-item.simple-select,
.radio-item.simple-select {
  display: none;
}
.hide:not(.match-in-children) {
  display: none;
}
.react-dropdown-tree-select .dropdown {
  width: 100%;
  position: relative;
  display: table;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  width: 100%;
  padding: 4px;
  line-height: 20px;
  display: inline-block;
  overflow: auto;
  border: 1px solid var(--color-gray-800);
  background: var(--color-gray-100);
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-6q0nyr-Svg'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: right;
  color: var(--color-gray-400);
  padding-left: 26px;
  margin-right: 6px;
  margin-left: 6px;
  position: absolute;
  top: 10px;
  right: 5px;
  height: 30px;
  opacity: 0.2;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-6q0nyr-Svg'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: right;
  color: var(--color-gray-400);
  padding-left: 26px;
  margin-right: 6px;
  margin-left: 6px;
  position: absolute;
  top: 10px;
  right: 5px;
  height: 30px;
  opacity: 0.8;
}

.react-dropdown-tree-select .dropdown .top {
  border: 2px solid var(--color-black);
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled input {
  opacity: 70%;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled .tag {
  color: var(--color-gray-600);
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  max-width: 90vw;
  position: absolute;
  padding: 4px;
  z-index: 2;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
  margin: 0;
  padding: 0;
}

.groupHeader {
  position: relative;
  width: 400px;
  height: 30px;
}

.groupHeader > label > input {
  display: none;
}

.groupHeader > label {
  position: absolute;
  top: 4px;
  left: 20px;
  pointer-events: none;
}

.groupHeader > i {
  width: 100%;
  display: block;
  text-decoration: none;
}

.search::placeholder {
  color: var(--color-gray-800);
}

.no-matches {
  display: block;
  min-width: 300px;
  padding: 6px 0 6px 1.5rem;
}
