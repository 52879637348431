.modal-header {
  background: var(--color-gray-100);
  border-bottom: 0 !important;
  padding: 1.5rem 2rem 1.5rem 1.5rem !important;
  font-size: 1.1rem;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-heavy);
  color: var(--color-gray-800) !important;
}

.modal-body {
  background: var(--color-white);
}

.modal-footer {
  border-top: 0 !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 549px) {
  .modal.show {
    padding: 0 !important; // override inline padding-right added from js
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}
