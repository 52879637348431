.badge {
  border-radius: 3px;
  padding: 0.25rem 0.5rem !important;
  line-height: 13px !important;
  font-family: var(--font-heavy);
  font-size: 0.9rem !important;
}

.badge-dark {
  background: var(--color-gray-900);
  color: var(--color-white);
}

.badge-light-gray {
  background: var(--color-gray-200);
  color: var(--color-gray-800);
}

.badge-green {
  background: var(--color-green);
  color: var(--color-white);
}

.badge-blue {
  background: var(--color-dark-blue) !important;
  color: var(--color-white);
}

.badge-transparent {
  background: transparent;
  color: var(--color-gray-800);
}

.badge-transparent-light {
  background: transparent;
  color: var(--color-gray-600);
}

.badge-white {
  background: var(--color-white);
  color: var(--color-gray-900);
}
